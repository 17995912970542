import Markdown from 'react-markdown'
import NextImage from '../../elements/image'
import ButtonLink from '../../elements/button-link'
import { getButtonAppearance } from '../../../utils/button'


export default function Hero({data}) {
        if (data.smallTextWithLink) return HeroSideBySide({data});
        return HeroBigPicture({data});
}

function HeroSideBySide({ data }) {
    return (
        <section className="container flex flex-col md:flex-row justify-between pt-12">
            {/* Left column for content */}
            <div className="flex-1 sm:pr-8">
                {/* Hero section label */}
                <p className="uppercase tracking-wide font-semibold text-sm md:text-base">{data.label}</p>
                {/* Big title */}
                <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 text-3xl md:text-3xl">{data.title}</h1>
                {/* Description paragraph */}
                <p className="text-xl md:text-2xl mb-6 font-semibold text-crimson">{data.description}</p>
                {/* Buttons row */}
                <div className="flex flex-row flex-wrap gap-4 text-crimson text-lg md:text-xl place-content-center">
                    {data.buttons.map((button) => (
                        <ButtonLink
                            button={button}
                            appearance={getButtonAppearance(button.type, "primary")}
                            key={button.id}
                        />
                    ))}
                </div>
                {/* Small rich text */}
                <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
                    <Markdown>{data.smallTextWithLink}</Markdown>
                </div>
            </div>
            {/* Right column for the image */}
            <div className="flex-shrink-0 w-full md:w-6/12 mt-6 md:mt-0">
                <NextImage media={data.picture} />
            </div>
        </section>
    );
}

function HeroBigPicture({ data }) {
    return (
        <section className="relative w-full min-h-screen max-h-screen flex flex-col pt-12">
            <div className="flex flex-col items-center justify-center p-4">
                <h1 className="text-3xl text-chocolate font-bold">{data.title}</h1>
            </div>
            <NextImage media={data.picture} className="w-full h-auto" />
            <div className="flex flex-col items-center justify-center p-4">
                <p className="text-xl md:text-2xl text-crimson mt-4">{data.description}</p>
                {/* Buttons row */}
                <div className="flex flex-row flex-wrap gap-4 text-crimson text-lg md:text-xl place-content-center mt-4">
                    {data.buttons.map((button) => (
                        <ButtonLink
                            button={button}
                            appearance={getButtonAppearance(button.type, "primary")}
                            key={button.id}
                        />
                    ))}
                </div>
                {/* Small rich text */}
                <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
                    <Markdown>{data.smallTextWithLink}</Markdown>
                </div>
            </div>
        </section>
    );
}